import { all, put, takeEvery, call } from 'redux-saga/effects';
import { notify } from 'store';
import { ActionTypes, SubmitUploadLinksActionType } from './actionTypes';
import * as ActionCreators from './actionCreator';
import * as services from './services';
import { UserType } from 'components/Home/types';
import { errorMsgResolver } from '../utils';

function* submitLinksUpload({ payload }: SubmitUploadLinksActionType) {
  try {
    yield put(ActionCreators.triggerLoadingAction(true));
    const { cik, companyId, entries, user, ticker } = payload;

    // Validate inputs
    entries.forEach((entry) => {
      if (!entry.url) {
        throw Error('URL is required');
      } else if (!entry.url.startsWith('https://www.sec.gov/')) {
        throw Error('URL is invalid, please make sure its a valid sec.gov URL');
      }
    });

    const username = (user as UserType)?.username ?? 'Anonymous';
    const jobName = `${username} - ${new Date().toISOString().split('T')[0]} - ticker: ${ticker}`;
    yield call(services.uploadLinks, jobName, companyId, cik, entries);
    notify({ message: 'Links uploaded successfully', severity: 'success', open: true });
  } catch (e) {
    notify({ message: errorMsgResolver(e), severity: 'error', open: true });
  } finally {
    yield put(ActionCreators.triggerLoadingAction(false));
  }
}

export default function* watcher() {
  yield all([takeEvery(ActionTypes.SUBMIT_UPLOAD_LINKS, submitLinksUpload)]);
}
