import * as Yup from 'yup';
import { OptionType } from 'ui-kit';

export const CompanyFormSchema = Yup.object().shape({
  ticker: Yup.string().required(),
  name: Yup.string().required(),
  industry: Yup.object().shape({
    label: Yup.string().required()
  }),
  sector: Yup.string().required(),
  active: Yup.boolean(),
  is_private: Yup.boolean(),
  identifiers: Yup.array().of(
    Yup.object().shape({
      identifier_type: Yup.string().required(),
      identifier_value: Yup.string().required()
    })
  )
});

export interface CompanyFormType {
  ticker?: string;
  name?: string;
  cik?: string;
  identifierValue?: string;
  identifierType?: OptionType;
  industry?: OptionType;
  sector?: string;
  industry_fk?: number;
}
