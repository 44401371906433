import { ComboBox, Dialog, OptionType } from 'ui-kit';
import { makeStyles } from '@material-ui/core/styles';
import { Box, FormControlLabel, Checkbox, TextField } from '@material-ui/core';
import FormHelperText from '@material-ui/core/FormHelperText';
import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { UserTier, UserType, User } from '../types';
import { USER_TIER_OPTIONS, USER_TYPE_OPTIONS } from '../constants';
import { createTrailUser, updateUser } from '../services';
import { FormikType, TickerDropdown, notify } from 'store';
import { errorMsgResolver } from '../../Documents/utils';
import Button from '@material-ui/core/Button';

interface IUserModalProps {
  user?: User | undefined;
  isOpen: boolean;
  handleClose: (arg0: boolean) => void;
  mode?: 'CREATE' | 'EDIT';
}

const useStyles = makeStyles({
  root: {
    width: 350,
    display: 'flex',
    flex: '1',
    flexDirection: 'column',
    justifyContent: 'space-around',
    height: '28vh',
    margin: 'auto'
  },
  input: {
    width: '350px',
    height: '30px'
  }
});

const EditUserFormType = Yup.object().shape({
  email: Yup.string().email().required(),
  tier: Yup.object()
    .shape({
      label: Yup.string(),
      value: Yup.string()
    })
    .required('Tier is required'),
  userType: Yup.object().shape({
    label: Yup.string(),
    value: Yup.string()
  })
});

export interface IUserType {
  id?: number;
  email?: string;
  tier?: OptionType | undefined;
  userType: OptionType | undefined;
  is_active?: boolean;
  isTrialTier?: boolean;
  ticker?: OptionType[];
}

const UserModal = ({ handleClose, isOpen, user, mode = 'CREATE' }: IUserModalProps) => {
  const classes = useStyles();
  const [isEditingUser, setIsEditingUser] = useState(false);
  const [linkGenerated, setLinkGenerated] = useState<string | undefined>(undefined);
  const textRef = React.useRef<HTMLInputElement>(null);

  const closeAndReset = (refresh = false) => {
    formik.resetForm();
    setLinkGenerated(undefined);
    handleClose(refresh);
  };

  const handleCreateTrailUser = async ({ email, isTrialTier, userType, ticker }: IUserType) => {
    const {
      trialAccount: { hashCode }
    }: { trialAccount: { hashCode: string } } = await createTrailUser(
      email as string,
      isTrialTier as boolean,
      ticker?.map((o) => o.value).join(',') as string,
      userType?.label as string
    );
    if (hashCode) {
      setLinkGenerated(
        `https://marketplace.daloopa.com/sign-up?email=${email as string}&hashcode=${hashCode}`
      );
      // closeAndReset(true);
    }
    notify({
      message: 'user created successfully',
      severity: 'success',
      open: true
    });
  };

  const handleUpdateUser = async ({ id, tier, userType, is_active }: IUserType) => {
    const { success, error }: { success: boolean; error: string } = await updateUser(
      (id as unknown) as string,
      tier?.value as UserTier,
      userType?.value as UserType,
      is_active
    );

    if (success) {
      closeAndReset(true);
      notify({
        message: 'user updated successfully',
        severity: 'success',
        open: true
      });
    } else {
      notify({
        message: error,
        severity: 'error',
        open: true
      });
    }
  };

  const formik = useFormik<IUserType>({
    initialValues: (mode === 'EDIT'
      ? {
          id: user?.id,
          email: user?.email,
          tier: USER_TIER_OPTIONS.find((opt) => opt.label === user?.tier) as OptionType,
          userType: USER_TYPE_OPTIONS.find((opt) => opt.label === user?.user_type) as OptionType,
          is_active: user?.is_active
        }
      : {
          email: '',
          tier: undefined,
          userType: undefined,
          isTrialTier: true,
          ticker: []
        }) as IUserType,
    isInitialValid: false,
    enableReinitialize: true,
    validateOnBlur: false,
    validateOnChange: true,
    validationSchema: EditUserFormType,

    validate: ({ tier, userType, isTrialTier, ticker }: IUserType) => {
      if (mode === 'EDIT' && tier === undefined) {
        return { tier: 'Select a tier' };
      }
      if (mode === 'CREATE' && isTrialTier && !ticker?.length) {
        return { ticker: 'Select a ticker' };
      }
      if (
        (tier?.value === 'enterprise_tier' || (mode === 'CREATE' && !isTrialTier)) &&
        !userType?.value
      ) {
        return { userType: 'Select a user type' };
      }
    },
    onSubmit: async ({ id, tier, userType, is_active, email, isTrialTier, ticker }) => {
      try {
        setIsEditingUser(true);

        if (mode === 'CREATE') {
          await handleCreateTrailUser({
            email,
            isTrialTier,
            ticker,
            userType
          });
        } else {
          await handleUpdateUser({
            id,
            tier,
            userType,
            is_active
          });
        }
      } catch (error) {
        notify({
          message: errorMsgResolver(error as string),
          severity: 'error',
          open: true
        });
      }
      setIsEditingUser(false);
    }
  });

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(linkGenerated as string);
    textRef.current?.focus();
    notify({
      message: 'copied to clipboard',
      severity: 'success',
      open: true
    });
  };

  return (
    <Dialog
      isOpen={isOpen}
      title={mode === 'CREATE' ? 'Create Trial User' : 'Update User'}
      handleClose={() => closeAndReset(false)}
      width={'sm'}
      hideDialogActions={mode === 'CREATE' ? true : false}
      handleOk={formik.handleSubmit}
      isSaveDisabled={!formik.isValid}
      minHeight={'25vh'}
      isLoading={isEditingUser}
    >
      <Box className={classes.root}>
        {mode === 'CREATE' ? (
          <TextField
            type="email"
            name="email"
            placeholder="Email Address"
            InputLabelProps={{
              shrink: true
            }}
            value={formik.values.email}
            onChange={formik.handleChange}
            variant="outlined"
            error={!!formik.errors.email}
            helperText={formik.errors.email}
          />
        ) : (
          <Box>{formik.values.email}</Box>
        )}
        {mode === 'CREATE' && (
          <FormControlLabel
            control={
              <Checkbox
                checked={formik.values.isTrialTier}
                onChange={() => formik.setFieldValue('isTrialTier', !formik.values.isTrialTier)}
                color="primary"
                name="active"
              />
            }
            label="trail"
          />
        )}
        {mode === 'CREATE' && formik.values.isTrialTier && (
          <Box>
            <TickerDropdown
              formik={(formik as unknown) as FormikType}
              selectedValue={formik.values.ticker}
              isMulti
            />
            <FormHelperText error={!!formik.errors.ticker?.length}>
              {formik.errors.ticker}
            </FormHelperText>
          </Box>
        )}
        {mode === 'EDIT' && (
          <ComboBox
            name="tier"
            placeholder="Select Tier"
            value={formik.values.tier}
            setFieldValue={(field, value) => {
              void formik.setFieldValue('tier', value as OptionType);
              void formik.setFieldTouched('tier', true);
            }}
            options={USER_TIER_OPTIONS}
            error={!!formik.errors.tier}
            helperText={formik.errors.tier}
          />
        )}

        {(formik.values.tier?.value === 'enterprise_tier' ||
          (mode === 'CREATE' && !formik.values.isTrialTier)) && (
          <ComboBox
            name="userType"
            placeholder="Select userType"
            value={formik.values.userType}
            setFieldValue={(field, value) => {
              void formik.setFieldValue('userType', value as OptionType);
              void formik.setFieldTouched('userType', true);
            }}
            options={USER_TYPE_OPTIONS}
            error={!!formik.errors.userType}
            helperText={formik.errors.userType}
          />
        )}

        {mode === 'EDIT' && (
          <FormControlLabel
            control={
              <Checkbox
                checked={formik.values.is_active}
                onChange={() => {
                  formik.setFieldValue('is_active', !formik.values.is_active);
                  formik.setFieldTouched('is_active', true);
                }}
                color="primary"
                name="active"
              />
            }
            label="Active"
          />
        )}
        {linkGenerated && (
          <TextField inputRef={textRef} variant="outlined" multiline value={linkGenerated} />
        )}

        {mode === 'CREATE' && (
          <Box>
            <Button
              color="primary"
              disabled={!formik.isValid || !!linkGenerated}
              onClick={() => formik.handleSubmit()}
            >
              Create Signup link
            </Button>

            <Button color="primary" disabled={!linkGenerated} onClick={handleCopyToClipboard}>
              Copy Signup link
            </Button>
            <Button
              color="primary"
              disabled={!formik.dirty}
              onClick={() => {
                formik.resetForm();
                setLinkGenerated(undefined);
              }}
            >
              Reset form
            </Button>
          </Box>
        )}
      </Box>
    </Dialog>
  );
};

export default UserModal;
