import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(() => ({
  form: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexFlow: 'column'
  },
  field: {
    width: '340px',
    '& > div': {
      height: '45px'
    }
  },
  formHeader: {
    fontWeight: 700
  },
  fieldContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: '1em 0',
    '& .MuiFormHelperText-root': {
      marginLeft: 0,
      fontSize: '10px'
    }
  },
  comboBoxInput: {
    '& > div > div': {
      width: '21.25em'
    },
    margin: '.5em 0'
  },
  checkboxInput: {
    width: '10.75em',
    margin: '0 10px'
  },
  chipsInput: {
    alignItems: 'start',
    '& > span': {
      marginBottom: '10px'
    },
    '& > div': {
      width: '21.25em'
    }
  },
  identifiersContainer: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 8,
    paddingBottom: 8
  },
  formGrid: {
    display: 'grid',
    width: '100%',
    gridTemplateColumns: '1fr 1fr',
    gap: 4,
    alignItems: 'center',
    justifyItems: 'center'
  },
  addIdentifier: {
    alignSelf: 'center',
    justifySelf: 'self-end'
  },
  deleteIdentifierContainer: {
    marginLeft: 48
  }
}));
