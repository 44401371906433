import React, { Ref, useEffect, useRef } from 'react';
import { CompanyType, confirm, EntityGrid } from 'store';
import { GridRowData } from '@material-ui/data-grid';

import CompaniesActions from './CompaniesActions';
import { getCompaniesColDef } from './constants';
import { useAction } from './hooks/useAction';
import useAuthorization from 'hooks/useAuthorization';
import { PermissionKeys } from 'config/permission-keys';
import CompanyForm from './CompanyForm';
import { EntityGridRef } from 'store/src/EntityGrid/types';
import { useSelector } from 'react-redux';
import { Selectors } from './state';

const Companies: React.FC = () => {
  const actions = useAction();

  const canDelete = useAuthorization(PermissionKeys.CAN_DELETE_COMPANIES);
  const canEdit = useAuthorization(PermissionKeys.CAN_EDIT_COMPANIES);
  const gridRef: Ref<EntityGridRef> = useRef<EntityGridRef>(null);
  const isAddCompanySuccess = useSelector(Selectors.selectIsAddCompanySuccess);
  const isEditCompanySuccess = useSelector(Selectors.selectIsEditCompanySuccess);

  useEffect(() => {
    actions.loadIndustriesAction();
  }, []);

  useEffect(() => {
    if (isEditCompanySuccess) {
      gridRef.current?.refresh();
      actions.setRequestStatus({ isEditSuccess: false });
    }
    if (isAddCompanySuccess) {
      gridRef.current?.refresh();
      actions.setRequestStatus({ isAddSuccess: false });
    }
  }, [isEditCompanySuccess, isAddCompanySuccess]);

  const onDeleteRow = (row: GridRowData) => {
    confirm('Delete Confirmation', 'Are you sure you want to delete this company ?', () => {
      actions.deleteCompanyAction({ companyIds: [row.id] });
    });
  };

  const onEditRow = (row: GridRowData) => {
    actions.openEditCompanyAction(row as CompanyType);
  };

  return (
    <>
      <EntityGrid
        entityName="company"
        actionContent={<CompaniesActions triggerAddCompany={actions.openAddCompanyAction} />}
        rows={[]}
        columns={getCompaniesColDef(onEditRow, onDeleteRow, canDelete, canEdit)}
        searchableColumns={['ticker']}
        placeholder="Search by Ticker..."
        ref={gridRef}
      />
      <CompanyForm />
    </>
  );
};

export default Companies;
