import { createSelector } from 'reselect';
import { RootStateType } from 'store/root-reducer';
import { CompaniesStateType } from 'components/Companies/types';

export const selectCompanyFormStatus = createSelector(
  (state: RootStateType) => state.company,
  (company: CompaniesStateType) => company.companyFormStatus
);

export const selectActiveEditCompany = createSelector(
  (state: RootStateType) => state.company,
  (company: CompaniesStateType) => company.activeEditCompany
);

export const selectIsLoading = createSelector(
  (state: RootStateType) => state.company,
  (company: CompaniesStateType) => company.isLoading
);

export const selectIndustries = createSelector(
  (state: RootStateType) => state.company,
  (company) => company.industries
);

export const selectIsAddCompanySuccess = createSelector(
  (state: RootStateType) => state.company,
  (company: CompaniesStateType) => company.isAddSuccess
);

export const selectIsEditCompanySuccess = createSelector(
  (state: RootStateType) => state.company,
  (company: CompaniesStateType) => company.isEditSuccess
);
