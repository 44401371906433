export const HomeRoutes = {
  Companies: '/home/companies',
  Documents: '/home/documents',
  BatchLogs: '/home/batch-jobs',
  CompSheets: '/home/comp-sheets',
  ApiKeys: '/home/api-keys',
  Users: '/home/users',
  SnapshotModels: '/home/snapshot-models',
  IndustryModels: '/home/industry-models'
};
