import React from 'react';
import { GridColumns, GridRowData } from '@material-ui/data-grid';
import TableRowActions from 'store/src/TableRowActions';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

export const getCompaniesColDef: (
  onDeleteRow: (row: GridRowData) => void,
  onEditRow: (row: GridRowData) => void,
  canDelete: boolean,
  canEdit: boolean
) => GridColumns = (onEditRow, onDeleteRow, canDelete, canEdit) => [
  { field: 'id', headerName: 'ID', width: 50 },
  { field: 'name', headerName: 'Name', width: 150, resizable: true },
  {
    field: 'ticker',
    headerName: 'Ticker',
    width: 150,
    resizable: true
  },
  {
    field: 'industry_name',
    headerName: 'Industry',
    width: 210,
    resizable: true
  },
  {
    field: 'active',
    headerName: 'is Active',
    width: 150,
    renderCell: (params) => (params.row.active ? <CheckCircleIcon color="secondary" /> : '')
  },
  {
    field: 'is_private',
    headerName: 'is Private',
    width: 150,
    renderCell: (params) => (params.row.is_private ? <CheckCircleIcon color="secondary" /> : '')
  },
  {
    field: '',
    headerName: 'Action ',
    renderCell: (params) => (
      <TableRowActions
        onEditRow={() => onEditRow(params.row)}
        onDeleteRow={() => onDeleteRow(params.row)}
        canDelete={canDelete}
        canEdit={canEdit}
      />
    ),
    width: 200,
    filterable: false
  }
];

export const identifierTypesOptions = [
  { label: 'CIK', value: 'CIK' },
  { label: 'ISIN', value: 'ISIN' },
  { label: 'CUSIP', value: 'CUSIP' },
  { label: 'SEDOL', value: 'SEDOL' },
  { label: 'FIGI', value: 'FIGI' },
  { label: 'PRIVATE', value: 'PRIVATE' },
  { label: 'CompanyHouseId', value: 'CompanyHouseId' },
  { label: 'CapIQCompanyId', value: 'CapIQCompanyId' },
  { label: 'CapIQCompanyTicker', value: 'CapIQCompanyTicker' }
];
