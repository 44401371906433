import React, { useMemo } from 'react';
import Select from 'react-select';
import useStyles from './style';
import { onPeriodChangeType } from '../shared/types';
import { QuarterUtils } from '../shared/utils';

interface PeriodInputProps {
  onPeriodChange: onPeriodChangeType;
  value: string;
}

const PeriodInput: React.FC<PeriodInputProps> = ({ value, onPeriodChange }) => {
  const classes = useStyles();

  const options = useMemo(() => QuarterUtils.getQuarters(), []);

  const onChange = (option: { label: string; value: string } | null) => {
    onPeriodChange(option?.value ?? '');
  };

  return (
    <Select
      value={value ? { label: value, value: value } : undefined}
      onChange={(option) => onChange(option)}
      isSearchable={true}
      options={options}
      placeholder="Period"
      className={classes.root}
      menuPosition="fixed"
      menuPortalTarget={document.body}
      styles={{
        menuPortal: (provided) => ({ ...provided, zIndex: 1301 }),
        menu: (provided) => ({ ...provided, zIndex: 1301 })
      }}
    />
  );
};

export default PeriodInput;
