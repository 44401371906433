import React from 'react';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/DeleteForever';
import useStyles from './styles';
import AuthorizationGard from '../AuthorizationGard';

interface TableRowActionsProps {
  onDeleteRow: () => void;
  onEditRow: () => void;
  canDelete?: boolean;
  canEdit?: boolean;
}

const TableRowActions: React.FC<TableRowActionsProps> = ({
  onDeleteRow,
  onEditRow,
  canEdit = true,
  canDelete = true
}) => {
  const classes = useStyles();
  return (
    <Box>
      <AuthorizationGard isAuthorized={canEdit}>
        <IconButton
          disabled={!canEdit}
          color="primary"
          aria-label="upload picture"
          component="span"
          className={classes.actionBtn}
        >
          <EditIcon className={classes.editIcon} onClick={onEditRow} />
        </IconButton>
      </AuthorizationGard>
      <AuthorizationGard isAuthorized={canDelete}>
        <IconButton
          disabled={!canDelete}
          color="primary"
          aria-label="upload picture"
          component="span"
          className={classes.actionBtn}
        >
          <DeleteIcon className={classes.deleteIcon} onClick={onDeleteRow} />
        </IconButton>
      </AuthorizationGard>
    </Box>
  );
};

export default TableRowActions;
