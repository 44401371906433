import { ComboBox, Dialog, OptionType } from 'ui-kit';
import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Box } from '@material-ui/core';
import { notify, TickerDropdown } from 'store';
import { AccessMode } from '../types';
import { isEmpty } from 'lodash';
import { createMultipleUserCompanyAccess } from '../services';
import { errorMsgResolver } from '../../Documents/utils';
import { ACCESS_MODE_OPTIONS } from '../constants';

interface CreateUserCompanyAccessesModelProps {
  userId: string | undefined;
  isOpen: boolean;
  handleClose: () => void;
}
const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    width: 260,
    height: '20vh',
    margin: 'auto'
  }
});

export const CreateUserCompanyAccessesModel = ({
  isOpen,
  handleClose,
  userId
}: CreateUserCompanyAccessesModelProps) => {
  const classes = useStyles();
  const [selectedTickers, setSelectedTickers] = useState<OptionType[]>([]);
  const [selectedAccessMode, setSelectedAccessMode] = useState<OptionType>(ACCESS_MODE_OPTIONS[2]);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const closeAndReset = () => {
    handleClose();
    setSelectedAccessMode(ACCESS_MODE_OPTIONS[2]);
    setSelectedTickers([]);
  };

  const onSubmit = async () => {
    if (selectedAccessMode && !isEmpty(selectedTickers) && userId) {
      try {
        setIsSubmitting(true);
        const companyIds = selectedTickers?.map((ticker) => parseInt(ticker?.value));
        const { success, errors } = await createMultipleUserCompanyAccess(
          companyIds,
          userId,
          selectedAccessMode.value as AccessMode
        );
        if (success) {
          closeAndReset();
          notify({
            message: 'user company access created successfully',
            severity: 'success',
            open: true
          });
        } else {
          notify({
            message: errors?.join(','),
            severity: 'error',
            open: true
          });
        }
      } catch (error) {
        notify({
          message: errorMsgResolver(error as string),
          severity: 'error',
          open: true
        });
      }
      setIsSubmitting(false);
    }
  };

  return (
    <Dialog
      isLoading={isSubmitting}
      isOpen={isOpen}
      title={'Create User Company Access'}
      handleClose={closeAndReset}
      width={'sm'}
      hideDialogActions={false}
      handleOk={onSubmit}
      isSaveDisabled={isEmpty(selectedAccessMode) && isEmpty(selectedTickers)}
      minHeight={'20vh'}
    >
      <Box className={classes.root}>
        <TickerDropdown
          onValueChange={(field, value) => setSelectedTickers(value as OptionType[])}
          selectedValue={selectedTickers}
          isMulti={true}
        />

        <ComboBox
          name={'accessMode'}
          placeholder="Select Access Mode"
          value={selectedAccessMode}
          setFieldValue={(field, value) => setSelectedAccessMode(value as OptionType)}
          options={ACCESS_MODE_OPTIONS}
        />
      </Box>
    </Dialog>
  );
};
