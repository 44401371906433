import { GraphQL, gql, Axios } from 'core';
import { UploadDocumentReqType } from './types';
import { DELETE_DOCUMENT, UPLOAD_DOCUMENTS, UPLOAD_LINKS } from './endpoints';
import { UploadLinkEntry } from './UploadViaLink/types';
import { format } from 'date-fns';

export function uploadDocument(uploadDocumentRequest: UploadDocumentReqType) {
  uploadDocumentRequest.content = uploadDocumentRequest?.content.replace(
    'data:application/pdf;base64,',
    ''
  );
  return Axios.post(`${UPLOAD_DOCUMENTS}`, uploadDocumentRequest);
}

export function uploadLinks(jobName: string, cik: string, linkEntities: UploadLinkEntry[]) {
  const reqBody = {
    name: jobName,
    entities: linkEntities?.map((entity) => ({
      filing_date: format(new Date(entity.period ?? ''), 'dd/MM/yyyy'),
      cik: +cik,
      form_type: entity.type?.value,
      url: entity.url
    }))
  };
  return Axios.post(UPLOAD_LINKS, reqBody);
}

export function deleteDocument(documentId: string | undefined) {
  const reqBody = {
    document_id: documentId
  };
  return Axios.delete(DELETE_DOCUMENT, { data: reqBody });
}

export function updatePeriod(filingId: string | undefined, period: string | undefined) {
  return GraphQL.mutate({
    mutation: gql`
      mutation updateFilingPeriodMutation($filingId: String!, $period: String!) {
        updateFilingPeriod(filingId: $filingId, period: $period) {
          filing {
            documents {
              id
              companyId
              filingId
              filingType
              documentType
              period
              displayPeriods
              cik
            }
          }
        }
      }
    `,
    variables: {
      filingId,
      period
    },
    fetchPolicy: 'no-cache'
  });
}

export const getPrivateDocumentTypes = async (): Promise<string[]> => {
  const response = await GraphQL.query<{ getConfigurationSetting: string }>({
    query: gql`
      {
        getConfigurationSetting(key: "PRIVATE_DOCUMENT_TYPES")
      }
    `
  });
  const documentTypes = JSON.parse(response.data.getConfigurationSetting);
  return documentTypes as string[];
};
