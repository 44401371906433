import { EntityGrid, notify } from 'store';
import React, { useRef, useState } from 'react';
import { getColumns } from './constants';
import { UserCompanyAccessesModal } from './Modals/UserCompanyAccessesModal';
import {
  disableUsers,
  enableUsers,
  exportUsers,
  resetPassword,
  updateUserTier,
  updateUserType
} from './services';
import { errorMsgResolver } from '../Documents/utils';
import { Button, CircularProgress, makeStyles } from '@material-ui/core';
import Block from '@material-ui/icons/Block';
import { GridRowId } from '@material-ui/data-grid';
import { isEmpty } from 'lodash';
import AddOutlined from '@material-ui/icons/AddOutlined';
import Done from '@material-ui/icons/Done';
import { EntityGridRef } from 'store/src/EntityGrid/types';
import DownloadIcon from '@material-ui/icons/GetApp';
import UserModal from './Modals/UserModal';
import { User } from './types';

const useStyles = makeStyles((theme) => ({
  deactivateBtn: {
    backgroundColor: theme.palette.error.light,
    color: theme.palette.error.main,
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: theme.palette.error.light,
      color: theme.palette.error.main,
      boxShadow: 'none'
    },
    marginRight: 12
  },

  activateBtn: {
    backgroundColor: theme.palette.success.light,
    color: theme.palette.success.main,
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: theme.palette.success.light,
      color: theme.palette.success.main,
      boxShadow: 'none'
    },
    marginRight: 12
  },
  primaryBtn: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.main,
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.primary.main,
      boxShadow: 'none'
    },
    marginRight: 12
  },
  loader: {
    marginLeft: 8
  }
}));

const Users = () => {
  const classes = useStyles();
  const [selectedUserId, setSelectedUserId] = useState<string>();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedUserIds, setSelectedUserIds] = useState<GridRowId[]>([]);
  const [isCreateUserModalOpened, setIsCreateUserModalOpened] = useState(false);
  const [isEditUserModalOpened, setIsEditUserModalOpened] = useState(false);
  const [editingUser, setEditingUser] = useState<User>({} as User);
  const entityRef = useRef<EntityGridRef>(null);
  const [isExporting, setIsExporting] = useState(false);

  const onResetPassword = async (email: string): Promise<void> => {
    try {
      setIsLoading(true);
      const success = await resetPassword(email);
      if (success) {
        notify({
          message: 'user password reset successfully',
          severity: 'success',
          open: true
        });
      } else {
        notify({
          message: "Couldn't reset password",
          severity: 'error',
          open: true
        });
      }
    } catch (error) {
      notify({
        message: errorMsgResolver(error as string),
        severity: 'error',
        open: true
      });
    }
    setIsLoading(false);
  };

  const onDisableUsers = async () => {
    try {
      setIsLoading(true);
      const success = await disableUsers(selectedUserIds as string[]);
      if (success) {
        entityRef.current?.refresh?.();
        notify({
          message: 'users deactivated successfully',
          severity: 'success',
          open: true
        });
      } else {
        notify({
          message: "couldn't disable users",
          severity: 'error',
          open: true
        });
      }
    } catch (error) {
      notify({
        message: errorMsgResolver(error as string),
        severity: 'error',
        open: true
      });
    }
    setIsLoading(false);
  };

  const onEnableUsers = async () => {
    try {
      setIsLoading(true);
      const success: boolean = await enableUsers(selectedUserIds as string[]);
      if (success) {
        entityRef.current?.refresh?.();
        notify({
          message: 'users enabled successfully',
          severity: 'success',
          open: true
        });
      } else {
        notify({
          message: "couldn't enable users",
          severity: 'error',
          open: true
        });
      }
    } catch (error) {
      notify({
        message: errorMsgResolver(error as string),
        severity: 'error',
        open: true
      });
    }
    setIsLoading(false);
  };

  const onUpdateTier = async (userId: string, newTier: string) => {
    try {
      setIsLoading(true);
      const { success, error } = await updateUserTier(userId, newTier);
      if (success) {
        notify({
          message: 'user tier updated successfully',
          severity: 'success',
          open: true
        });
      } else if (error) {
        notify({
          message: error,
          severity: 'error',
          open: true
        });
      }
    } catch (error) {
      notify({
        message: errorMsgResolver(error as string),
        severity: 'error',
        open: true
      });
    }
    setIsLoading(false);
  };

  const onUpdateType = async (userId: string, newType: string) => {
    try {
      setIsLoading(true);
      const { success, error } = await updateUserType(userId, newType);
      if (success) {
        notify({
          message: 'user type updated successfully',
          severity: 'success',
          open: true
        });
      } else if (error) {
        notify({
          message: error,
          severity: 'error',
          open: true
        });
      }
    } catch (error) {
      notify({
        message: errorMsgResolver(error as string),
        severity: 'error',
        open: true
      });
    }
    setIsLoading(false);
  };

  const handleCloseCreateUser = (refresh: boolean) => {
    refresh && entityRef?.current?.refresh?.();
    setIsCreateUserModalOpened(false);
  };

  const onExportUsers = async () => {
    setIsExporting(true);
    const csvFile: string = await exportUsers();
    const a = document.createElement('a');
    a.style.display = 'none';
    document.body.appendChild(a);
    a.href = window.URL.createObjectURL(new Blob([csvFile], { type: 'text/csv' }));
    a.setAttribute('download', 'users.csv');
    a.click();
    window.URL.revokeObjectURL(a.href);
    document.body.removeChild(a);
    setIsExporting(false);
  };

  const onEditUser = (user: User) => {
    setEditingUser(user);
    setIsEditUserModalOpened(true);
  };

  return (
    <>
      <UserCompanyAccessesModal
        isOpen={!!selectedUserId}
        handleClose={() => setSelectedUserId(undefined)}
        userId={selectedUserId}
      />
      <UserModal isOpen={isCreateUserModalOpened} handleClose={handleCloseCreateUser} />
      <UserModal
        mode="EDIT"
        isOpen={isEditUserModalOpened}
        handleClose={(refresh) => {
          setIsEditUserModalOpened(false);
          refresh && entityRef?.current?.refresh?.();
        }}
        user={editingUser}
      />
      <EntityGrid
        ref={entityRef}
        loading={isLoading}
        entityName="users"
        rows={[]}
        columns={getColumns((userId) => setSelectedUserId(userId), onResetPassword, onEditUser)}
        searchableColumns={['email']}
        placeholder="Search user..."
        checkboxSelection={true}
        disableSelectionOnClick={true}
        onSelectionModelChange={(selectionModel) => setSelectedUserIds(selectionModel)}
        selectionModel={selectedUserIds}
        onCellEditCommit={(params) => {
          if (params.field === 'tier') {
            onUpdateTier(`${params.id}`, params.value as string);
          } else if (params.field === 'user_type') {
            onUpdateType(`${params.id}`, params.value as string);
          }
        }}
        actionContent={
          <>
            <Button
              variant="contained"
              startIcon={<DownloadIcon />}
              onClick={onExportUsers}
              className={classes.primaryBtn}
            >
              Export Users
              {isExporting && <CircularProgress size={20} className={classes.loader} />}
            </Button>
            <Button
              variant="contained"
              startIcon={<Done />}
              onClick={onEnableUsers}
              className={classes.activateBtn}
              disabled={isEmpty(selectedUserIds) || isLoading}
            >
              Activate
            </Button>
            <Button
              variant="contained"
              startIcon={<Block />}
              onClick={onDisableUsers}
              className={classes.deactivateBtn}
              disabled={isEmpty(selectedUserIds) || isLoading}
            >
              Deactivate
            </Button>
            <Button
              variant="contained"
              startIcon={<AddOutlined />}
              onClick={() => setIsCreateUserModalOpened(true)}
              className={classes.primaryBtn}
            >
              Create User
            </Button>
          </>
        }
      />
    </>
  );
};

export default Users;
