import { CompanyType, EntityGrid, notify } from 'store';
import React, { useCallback, useMemo, useState } from 'react';
import {
  downloadModalSnapshot,
  fetchTokenAndDownloadModel,
  generateApiSnapshot,
  generateSeparateAnnualFile,
  getSnapshotModels
} from './services';
import { GridColumns } from '@material-ui/data-grid';
import { format } from 'date-fns';
import { Box, Button, Chip, useTheme } from '@material-ui/core';
import {
  GenerateStatus,
  Log,
  ModelSnapshotTypes,
  ModelType,
  ModelTypeKeys,
  SnapshotModel
} from './types';
import { useStyles } from './styles';
import { GENERATE_STATUS_COLOR_MAP, TYPE_TITLE_MAP } from './constants';
import excelImg from '../../assets/excel.png';

export const SnapshotModels = () => {
  const classes = useStyles();
  const [isGenerating, setIsGenerating] = useState(false);
  const {
    palette: { white }
  } = useTheme();

  const getType = useCallback((model: SnapshotModel) => {
    const type: ModelTypeKeys[] = [];
    Object.keys(ModelType)
      .filter((modelType) => modelType in model && model[modelType as ModelTypeKeys])
      .forEach((modelType) => {
        type.push(modelType as ModelTypeKeys);
      });
    return type;
  }, []);

  const onGenerateSeparateAnnualFile = useCallback(async (modelId: string) => {
    setIsGenerating(true);
    try {
      const success = await generateSeparateAnnualFile(modelId);
      if (success) {
        notify({
          message: 'Generated Separate Annual File Successfully',
          severity: 'success',
          open: true
        });
      } else {
        notify({
          message: 'Failed To Generate Separate Annual File',
          severity: 'error',
          open: true
        });
      }
    } catch (e) {
      notify({
        message: (e as Error)?.toString(),
        severity: 'error',
        open: true
      });
    } finally {
      setIsGenerating(false);
    }
  }, []);

  const onGenerateAPISnapshot = useCallback(async (modelId: string) => {
    setIsGenerating(true);
    try {
      const success = await generateApiSnapshot(modelId);
      if (success) {
        notify({
          message: 'Generated API Snapshot Successfully',
          severity: 'success',
          open: true
        });
      } else {
        notify({
          message: 'Failed To Generate API Snapshot',
          severity: 'error',
          open: true
        });
      }
    } catch (e) {
      notify({
        message: (e as Error)?.toString(),
        severity: 'error',
        open: true
      });
    } finally {
      setIsGenerating(false);
    }
  }, []);

  const columns: GridColumns = useMemo(
    () => [
      {
        field: 'id',
        headerName: 'Model ID',
        width: 140,
        filterable: false,
        sortable: false
      },
      {
        field: 'companyTicker',
        headerName: 'Company Ticker',
        width: 200,
        renderCell: (params) => (params.row.company as CompanyType).ticker ?? '-',
        filterable: false,
        sortable: false
      },
      {
        field: 'lastUpdated',
        headerName: 'Updated At',
        width: 150,
        renderCell: (params) => format(new Date(params.row?.updatedAt), 'dd MMM yyyy HH:MM'),
        filterable: false,
        sortable: false
      },
      {
        field: 'qqqqFySnapshotLogMessage',
        headerName: 'QQQQFY Snapshot Message',
        width: 250,
        renderCell: (params) => {
          const log = (params.row as SnapshotModel).separateAnnualsLog;
          return log
            ? log.message
            : (params.row as SnapshotModel).qqqqFySnapshot
            ? GenerateStatus.COMPLETED.toLowerCase()
            : GenerateStatus.FAILED.toLowerCase();
        },
        filterable: false,
        sortable: false
      },
      {
        field: 'qqqqFySnapshotLogStatus',
        headerName: 'QQQQFY Snapshot Status',
        width: 250,
        sortable: false,
        type: 'boolean',
        filterable: true,
        renderCell: (params) => {
          const log = (params.row as SnapshotModel).separateAnnualsLog;
          const status: GenerateStatus = log
            ? log.status
            : (params.row as SnapshotModel).qqqqFySnapshot
            ? GenerateStatus.COMPLETED
            : GenerateStatus.FAILED;
          return (
            <Chip
              style={{
                backgroundColor: GENERATE_STATUS_COLOR_MAP[status],
                color: white.light
              }}
              color={'primary'}
              label={status}
            />
          );
        }
      },
      {
        field: 'generate',
        headerName: 'Annual File',
        width: 200,
        renderCell: (params) => (
          <Button
            variant={'text'}
            color={'primary'}
            onClick={() => onGenerateSeparateAnnualFile(params.row?.id)}
          >
            Generate Annual File
          </Button>
        ),
        filterable: false,
        sortable: false
      },
      {
        field: 'apiSnapshotLogMessage',
        headerName: 'API Snapshot Message',
        width: 250,
        renderCell: (params) => {
          const log = (params.row as SnapshotModel).apiSnapshotLog;
          return log
            ? log.message
            : (params.row as SnapshotModel).apiSnapshot
            ? GenerateStatus.COMPLETED.toLowerCase()
            : GenerateStatus.FAILED.toLowerCase();
        },
        filterable: false,
        sortable: false
      },
      {
        field: 'apiSnapshotLogStatus',
        headerName: 'API Snapshot Status',
        type: 'boolean',
        width: 250,
        sortable: false,
        filterable: true,
        renderCell: (params) => {
          const log = params.row.apiSnapshotLog as Log;
          const status = log
            ? log.status
            : (params.row as SnapshotModel).apiSnapshot
            ? GenerateStatus.COMPLETED
            : GenerateStatus.FAILED;
          return (
            <Chip
              style={{
                backgroundColor: GENERATE_STATUS_COLOR_MAP[status],
                color: white.light
              }}
              color={'primary'}
              label={status}
            />
          );
        }
      },
      {
        field: 'generate api snapshot',
        headerName: 'API Snapshot',
        width: 200,
        renderCell: (params) => (
          <Button
            variant={'text'}
            color={'primary'}
            onClick={() => onGenerateAPISnapshot(params.row?.id)}
          >
            Generate API Snapshot
          </Button>
        ),
        filterable: false,
        sortable: false
      },

      {
        field: 'status',
        headerName: 'Action',
        minWidth: 700,
        sortable: false,
        filterable: false,
        renderCell: (params) => (
          <div className={classes.statusWrapper}>
            {getType(params.row as SnapshotModel).map((type) => (
              <Box
                className={classes.chip}
                key={type}
                onClick={() =>
                  [ModelType.qqqqFySnapshot, ModelType.apiSnapshot].includes(type as ModelType)
                    ? downloadModalSnapshot(
                        (params.row as SnapshotModel).id,
                        type === ModelType.apiSnapshot
                          ? ModelSnapshotTypes.API_SNAPSHOT
                          : ModelSnapshotTypes.SEPARATE_ANNUALS
                      )
                    : fetchTokenAndDownloadModel((params.row as SnapshotModel).id, type)
                }
              >
                <img src={excelImg} alt={'excel'} />
                {TYPE_TITLE_MAP[type]}
              </Box>
            ))}
          </div>
        )
      }
    ],
    [
      onGenerateSeparateAnnualFile,
      classes.statusWrapper,
      getType,
      onGenerateAPISnapshot,
      white.light
    ]
  );

  return (
    <EntityGrid
      actionContent={null}
      entityName="Snapshot Models"
      isGqlAPI={true}
      graphqlService={getSnapshotModels}
      rows={[]}
      columns={columns}
      searchableColumns={['companyTicker', 'companyName']}
      placeholder="Search by Company..."
      loading={isGenerating}
      isServerHandled={true}
      isRowSelectable={() => false}
    />
  );
};
