import { GridFilterItem } from '@material-ui/data-grid';
import { TableOperatorsMap } from './constants';

export const buildFilterQueryParam = (
  filterConfig: GridFilterItem,
  searchableColumns: string[]
) => {
  if (!filterConfig.value || searchableColumns?.length === 0) {
    return '{}';
  }

  const filterValue = filterConfig?.value as string;
  const filterItems = searchableColumns
    .map((col) => `"${col}__icontains":"${filterValue?.trim()}"`)
    .join(',');

  return `{${filterItems}}`;
};

/**
 * Map the operator coming from the table to the operator Backend can understand
 * Ex:
 * table filter operator is Equals
 * Backend should receive Exact
 */
export const mapOperator = (filterOperator: string) => TableOperatorsMap[filterOperator];
