export enum PermissionKeys {
  // Companies Tab
  CAN_VIEW_COMPANIES = 'backend | companies | Can view companies',
  CAN_DELETE_COMPANIES = 'backend | companies | Can delete companies',
  CAN_EDIT_COMPANIES = 'backend | companies | Can change companies',
  CAN_ADD_COMPANIES = 'backend | companies | Can add companies',

  // Documents Tab
  CAN_UPLOAD_CRAWLER_JOBS = 'backend | crawler job | Can add crawler job',
  CAN_UPLOAD_DOCUMENTS = 'backend | documents | Can add documents',
  CAN_VIEW_DOCUMENT = 'backend | documents | Can view documents',
  CAN_DELETE_DOCUMENT = 'backend | documents | Can delete documents',
  // CAN_EDIT_DOCUMENT = 'backend | documents | Can change documents',
  // TODO revert to 'Can change documents' once its supported in BE
  CAN_EDIT_DOCUMENT = 'backend | documents | Can add documents',

  // Crawler Tab
  CAN_VIEW_CRAWLER_JOBS = 'backend | crawler job | Can view crawler job',
  CAN_ADD_API_KEY = 'backend | api key | Can add api key'
}
