import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) =>
  createStyles({
    linkInput: {
      width: '13em',
      height: '2.5em',
      borderRadius: '5px',
      border: '1.21739px solid #DDDDDD',
      margin: '0 10px',
      padding: '0 5px'
    },
    cikContainer: {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr 3fr',
      alignItems: 'center',
      padding: '0 4em 1em'
    },
    linksContainer: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      padding: '1em'
    },
    emptyMsg: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      paddingTop: '3em',
      fontWeight: 'bold',
      color: theme.palette.grey.A200
    },
    root: {
      '& .MuiTextField-root': {
        margin: theme.spacing(1)
      },
      '& label': {
        color: theme.palette.text.primary
      }
    },
    addRowsContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginBottom: '20px'
    },
    addRowsButton: {
      width: '74px',
      height: '40px',
      borderRadius: '5px'
    },
    addRowsLabel: {
      fontWeight: 500,
      color: theme.palette.text.hint
    },
    inputsRow: {
      background: theme.palette.white.main,
      boxShadow: '0px 0px 15px 6px rgba(24, 121, 249, 0.06)',
      borderRadius: '5px',
      padding: '19px 21px',
      display: 'grid',
      gridTemplateColumns: '1fr 3fr 5fr 1fr',
      gridGap: '20px',
      alignItems: 'center'
    },
    urlInput: {
      height: '2em',
      '& > .MuiInputBase-root': {
        height: '2em'
      },
      '& input': {
        height: '1em'
      }
    },
    closeIcon: {
      cursor: 'pointer'
    },
    jobNameInput: {
      width: '250px',
      height: '40px',
      borderRadius: '5px',
      border: '1.21739px solid #DDDDDD',
      margin: '0 10px',
      padding: '0 5px'
    }
  })
);
