import React, { useEffect, useMemo } from 'react';
import { Dialog, OptionType } from 'ui-kit';
import { Selectors } from 'components/Companies/state';
import { useSelector } from 'react-redux';
import ManageCompanyForm from './ManageCompanyForm';
import { CompanyType, FormikType } from 'store';
import { CompanyFormSchema } from './schema';
import { useFormik } from 'formik';
import { getChangedValues, getEditCompanyPayload, getExtraValues } from './utils';
import { useAction } from '../hooks/useAction';
import { isEmpty } from 'lodash';

const CompanyForm = () => {
  const { closeCompanyFormAction, submitAddCompanyAction, submitEditCompanyAction } = useAction();
  const activeCompany = useSelector(Selectors.selectActiveEditCompany);
  const formStatus = useSelector(Selectors.selectCompanyFormStatus);
  const isLoading = useSelector(Selectors.selectIsLoading);
  const industries = useSelector(Selectors.selectIndustries);

  const formik: FormikType<CompanyType> = useFormik<CompanyType>({
    initialValues: {
      ticker: activeCompany?.ticker ?? '',
      cik: activeCompany?.cik ?? '',
      name: activeCompany?.name ?? '',
      sector: activeCompany?.sector ?? '',
      is_private: false,
      active: true,
      disabled: activeCompany?.disabled ?? false,
      usersEmailsToAccessPrivateCompany: '',
      ...(activeCompany ?? {}),
      ...getExtraValues?.(industries, formStatus, activeCompany),
      identifiers: [...(activeCompany?.identifiers ?? [])]
    },
    isInitialValid:
      Boolean(activeCompany?.ticker) &&
      Boolean(activeCompany?.cik) &&
      Boolean(activeCompany?.name) &&
      Boolean(activeCompany?.sector),
    validationSchema: CompanyFormSchema,

    onSubmit: (values) => {
      formStatus === 'ADD'
        ? submitAddCompanyAction(values)
        : submitEditCompanyAction(
            getEditCompanyPayload({
              ...(getChangedValues(values, formik.initialValues) as CompanyType),
              emails: Boolean(values.is_private)
                ? !isEmpty(values?.usersEmailsToAccessPrivateCompany)
                  ? values?.usersEmailsToAccessPrivateCompany?.split(',')
                  : []
                : [],
              id: activeCompany?.id
            })
          );
    }
  });

  const dialogTitle = useMemo(() => {
    return formStatus == 'ADD' ? 'Add Company' : 'Edit Company';
  }, [formStatus]);
  const isOpen = useMemo(() => formStatus !== 'CLOSED', [formStatus]);

  useEffect(() => {
    if (!isOpen) {
      formik.resetForm();
    } else {
      formik.resetForm({
        values: {
          ticker: activeCompany?.ticker ?? '',
          name: activeCompany?.name ?? '',
          is_private: false,
          active: true,
          usersEmailsToAccessPrivateCompany: '',
          disabled: activeCompany?.disabled ?? false,
          ...(activeCompany ?? {}),
          ...getExtraValues?.(industries, formStatus, activeCompany),
          identifiers:
            activeCompany?.identifiers ??
            (formStatus === 'ADD'
              ? [{ identifier_type: '', identifier_value: '', id: -1, company: -1 }]
              : [])
        }
      });
    }
  }, [isOpen, formStatus]);

  return (
    <>
      <Dialog
        width="lg"
        isOpen={isOpen}
        title={dialogTitle}
        handleClose={closeCompanyFormAction}
        isSaveDisabled={!formik.isValid}
        handleOk={formik.submitForm}
        isLoading={isLoading}
      >
        <ManageCompanyForm formik={formik} isEdit={formStatus === 'EDIT'} />
      </Dialog>
    </>
  );
};

export default CompanyForm;
