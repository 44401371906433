import { Axios } from 'core';
import { GetTickersType } from './types';

export const FETCH_TICKERS = '/golden_gate/company';

export async function getTickers(tickerName: string): Promise<GetTickersType> {
  return await Axios.get(
    `${FETCH_TICKERS}?filters={"ticker__istartswith": "${tickerName}"}&page=1&page_size=10&order=-ticker`
  );
}
