import React from 'react';
import Box from '@material-ui/core/Box';
import { Button } from '@material-ui/core';
import AddBoxOutlined from '@material-ui/icons/AddBoxOutlined';
import useStyles from './styles';
import useAuthorization from 'hooks/useAuthorization';
import { PermissionKeys } from 'config/permission-keys';
import { AuthorizationGard } from 'store';

export interface CompaniesActionsPropTypes {
  triggerAddCompany?: () => void;
}

const CompaniesActions: React.FC<CompaniesActionsPropTypes> = ({ triggerAddCompany }) => {
  const classes = useStyles();
  const canAddCompany = useAuthorization(PermissionKeys.CAN_ADD_COMPANIES);

  return (
    <Box display="flex">
      <AuthorizationGard isAuthorized={canAddCompany}>
        <Button
          className={classes.root}
          variant="contained"
          color="primary"
          startIcon={<AddBoxOutlined />}
          onClick={triggerAddCompany}
          disabled={!canAddCompany}
        >
          Company
        </Button>
      </AuthorizationGard>
      <Box m={1} />
    </Box>
  );
};

export default CompaniesActions;
