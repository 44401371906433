import { CompaniesStateType } from 'components/Companies/types';
import { ActionType, ActionTypes } from './actionTypes';

const initialState: CompaniesStateType = {
  isLoading: false,
  activeEditCompany: undefined,
  companyFormStatus: 'CLOSED',
  industries: [],
  isEditSuccess: false,
  isAddSuccess: false
};

export default (
  state: CompaniesStateType = initialState,
  action: ActionType
): CompaniesStateType => {
  switch (action.type) {
    case ActionTypes.TRIGGER_LOADER:
      return {
        ...state,
        isLoading: action.payload
      };
    case ActionTypes.OPEN_ADD_COMPANY:
      return {
        ...state,
        companyFormStatus: 'ADD',
        activeEditCompany: undefined
      };
    case ActionTypes.OPEN_EDIT_COMPANY:
      return {
        ...state,
        companyFormStatus: 'EDIT',
        activeEditCompany: action.payload
      };
    case ActionTypes.CLOSE_COMPANY_FORM:
      return {
        ...state,
        companyFormStatus: 'CLOSED',
        activeEditCompany: undefined
      };
    case ActionTypes.UPDATE_INDUSTRIES:
      return {
        ...state,
        industries: action.payload
      };
    case ActionTypes.SET_REQUEST_STATUS:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};
