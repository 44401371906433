import { all, put, takeEvery, call } from 'redux-saga/effects';
import {
  ActionTypes,
  deleteCompanyActionType,
  submitAddCompanyActionType,
  submitEditCompanyActionType
} from './actionTypes';
import * as ActionCreators from './actionCreator';
import * as services from 'components/Companies/state/services';
import { notify } from 'store';
import { IndustryType } from '../types';
import { errorMsgResolver } from '../../Documents/utils';

function* deleteCompany({ payload }: deleteCompanyActionType) {
  try {
    yield put(ActionCreators.triggerLoaderAction(true));
    yield call(services.deleteCompany, payload);
    notify({ message: 'Company Deleted Successfully!', severity: 'success', open: true });
  } catch (error) {
    notify({ message: errorMsgResolver(error), severity: 'error', open: true });
  } finally {
    yield put(ActionCreators.triggerLoaderAction(false));
  }
}

function* editCompany({ payload }: submitEditCompanyActionType) {
  try {
    yield put(ActionCreators.triggerLoaderAction(true));
    yield call(services.editCompany, payload);
    notify({ message: 'Company Edited Successfully!', severity: 'success', open: true });
    yield put(ActionCreators.setRequestStatus({ isEditSuccess: true }));
    yield put(ActionCreators.closeCompanyFormAction());
  } catch (error) {
    notify({ message: errorMsgResolver(error), severity: 'error', open: true });
    yield put(ActionCreators.setRequestStatus({ isEditSuccess: false }));
  } finally {
    yield put(ActionCreators.triggerLoaderAction(false));
  }
}

function* addCompany({ payload }: submitAddCompanyActionType) {
  try {
    yield put(ActionCreators.triggerLoaderAction(true));
    yield call(services.addCompany, payload);
    notify({ message: 'Company Added Successfully!', severity: 'success', open: true });
    yield put(ActionCreators.setRequestStatus({ isAddSuccess: true }));
    yield put(ActionCreators.closeCompanyFormAction());
  } catch (error) {
    notify({ message: errorMsgResolver(error), severity: 'error', open: true });
    yield put(ActionCreators.setRequestStatus({ isAddSuccess: false }));
  } finally {
    yield put(ActionCreators.triggerLoaderAction(false));
  }
}

function* loadIndustries() {
  try {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const response: { data: IndustryType[] } = yield call(services.loadIndustries);
    yield put(ActionCreators.updateIndustriesAction(response.data));
  } catch (error) {
    notify({ message: errorMsgResolver(error), severity: 'error', open: true });
  }
}

export default function* watcher() {
  yield all([
    takeEvery(ActionTypes.DELETE_COMPANY, deleteCompany),
    takeEvery(ActionTypes.SUBMIT_EDIT_COMPANY, editCompany),
    takeEvery(ActionTypes.SUBMIT_ADD_COMPANY, addCompany),
    takeEvery(ActionTypes.LOAD_INDUSTRIES, loadIndustries)
  ]);
}
