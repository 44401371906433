import { GridColumns } from '@material-ui/data-grid';
import { format } from 'date-fns';
import { Chip } from '@material-ui/core';
import React from 'react';
import { AuthorizationGard, notify } from 'store';
import { DeleteButton } from 'store/src/EntityGrid/Actions';

export const getApiKeysColDef = (
  canDelete: boolean,
  onDelete: (id: number) => void
): GridColumns => [
  {
    field: 'id',
    headerName: 'ID',
    width: 100
  },
  {
    field: 'user',
    headerName: 'User E-mail',
    width: 250,
    renderCell: (params) => (params.row.user as { email: string }).email ?? '-'
  },
  {
    field: 'created_at',
    headerName: 'Created At',
    width: 250,
    renderCell: (params) => format(new Date(params.row?.created_at), 'dd MMM yyyy HH:MM')
  },
  {
    field: 'token',
    headerName: 'Token',
    width: 150,
    renderCell: (params) => {
      const apiKey = params.row?.token as string;
      const copyAPIKey = async () => {
        if (apiKey) {
          await navigator.clipboard.writeText(apiKey);
          notify({
            message: 'API Key Copied',
            severity: 'info',
            open: true
          });
        }
      };

      return <Chip label="Copy Api Key" variant="outlined" onClick={copyAPIKey} />;
    }
  },
  {
    field: '',
    headerName: 'Action ',
    renderCell: (params) => (
      <AuthorizationGard isAuthorized={canDelete}>
        <DeleteButton onDelete={() => onDelete(params.row.id)} />
      </AuthorizationGard>
    ),
    filterable: false,
    flex: 1
  }
];
