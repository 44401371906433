export const MIN_YEAR = 1995;
export const MAX_YEAR = 2026;

export const getQuarters = () => {
  let startYear = MIN_YEAR;
  const endYear = new Date().getFullYear() + 1;
  const quarterOptions = [];
  while (startYear <= endYear) {
    quarterOptions.push(
      { label: `${startYear}Q1`, value: `${startYear}Q1` },
      { label: `${startYear}Q2`, value: `${startYear}Q2` },
      { label: `${startYear}Q3`, value: `${startYear}Q3` },
      { label: `${startYear}Q4`, value: `${startYear}Q4` },
      { label: `${startYear}A`, value: `${startYear}A` }
    );
    startYear++;
  }
  return quarterOptions.reverse();
};
