import { DeleteCompanyRequestType, IndustryType } from '../types';
import { CompanyType, EditCompanyType } from 'store';

export enum ActionTypes {
  DELETE_COMPANY = 'DELETE_COMPANY',
  OPEN_EDIT_COMPANY = 'EDIT_COMPANY',
  OPEN_ADD_COMPANY = 'ADD_COMPANY',
  CLOSE_COMPANY_FORM = 'CLOSE_COMPANY_FORM',
  TRIGGER_LOADER = 'TRIGGER_LOADER',
  SUBMIT_ADD_COMPANY = 'SUBMIT_ADD_COMPANY',
  SUBMIT_EDIT_COMPANY = 'SUBMIT_EDIT_COMPANY',
  LOAD_INDUSTRIES = 'LOAD_INDUSTRIES',
  UPDATE_INDUSTRIES = 'UPDATE_INDUSTRIES',
  SET_REQUEST_STATUS = 'SET_REQUEST_STATUS'
}

export interface submitAddCompanyActionType {
  type: ActionTypes.SUBMIT_ADD_COMPANY;
  payload: CompanyType;
}

export interface submitEditCompanyActionType {
  type: ActionTypes.SUBMIT_EDIT_COMPANY;
  payload: EditCompanyType;
}

export interface deleteCompanyActionType {
  type: ActionTypes.DELETE_COMPANY;
  payload: DeleteCompanyRequestType;
}

export interface setRequestStatusActionType {
  type: ActionTypes.SET_REQUEST_STATUS;
  payload: Partial<{ isAddSuccess: boolean; isEditSuccess: boolean }>;
}

export interface triggerLoaderActionType {
  type: ActionTypes.TRIGGER_LOADER;
  payload: boolean;
}

export interface openAddCompanyActionType {
  type: ActionTypes.OPEN_ADD_COMPANY;
  payload: null;
}

export interface openEditCompanyActionType {
  type: ActionTypes.OPEN_EDIT_COMPANY;
  payload: CompanyType;
}

export interface closeCompanyFormActionType {
  type: ActionTypes.CLOSE_COMPANY_FORM;
  payload: null;
}

export interface loadIndustriesActionType {
  type: ActionTypes.LOAD_INDUSTRIES;
  payload: null;
}

export interface updateIndustriesActionType {
  type: ActionTypes.UPDATE_INDUSTRIES;
  payload: IndustryType[];
}

export type ActionType =
  | deleteCompanyActionType
  | triggerLoaderActionType
  | openAddCompanyActionType
  | openEditCompanyActionType
  | submitAddCompanyActionType
  | submitEditCompanyActionType
  | loadIndustriesActionType
  | updateIndustriesActionType
  | closeCompanyFormActionType
  | setRequestStatusActionType;
